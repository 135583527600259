import InputStyled from './InputStyled'

const Input = ({ title, type, placeholder, value, valueChange, dataId, minVal, maxVal, step, maxLength=45 }) => {

    const validateTextarea = (event) => {
        valueChange(event)
    }

    const validateTextinput = (event) => {
        if(event.target.value.length <= maxLength){
            valueChange(event)
        }
    }
    
    return (
        <InputStyled>
            <label>
                <p>{title}</p>
                { type === 'textarea' ? <textarea value={value} dataid={dataId} onChange={validateTextarea} /> :
                    <input 
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={validateTextinput}
                        dataid={dataId}
                        min={minVal}
                        max={maxVal}
                        setp={step}
                    />
                }
            </label>
        </InputStyled>
    )
}

export default Input