import { useCallback, useState } from "react";
import ImageUploadStyled from "./ImageUploadStyled";
import { useDropzone } from "react-dropzone";

const ImageUpload = ({ settings, setSettings }) => {
    const [uploadImage, setUploadImage] = useState();
    const [imageError, setImageError] = useState(false);

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                const preview = document.createElement("img");
                preview.className = "thumb";

                reader.onabort = () => console.log("file reading was aborted");
                reader.onerror = () => console.log("file reading has failed");
                reader.onload = (e) => {
                    // Preview
                    preview.src = e.target.result;
                    preview.onload = () => {

                        if (preview.width >= 3000 && preview.width >= 1925) {
                            setUploadImage(preview.src);

                            // setState
                            setSettings((current) => {
                                return {
                                    ...current,
                                    image: preview.src,
                                };
                            });
                            setImageError(false);
                        } else {
                            console.log(
                                "Imagesize Error (Resolution or Orientation)"
                            );
                            setImageError(true);
                        }
                    };
                };
                reader.readAsDataURL(file);
            });
        },
        [setSettings]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png",
    });

    const previewImage =
        uploadImage !== undefined ? (
            <></>
        ) : (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="101"
                height="64"
                viewBox="0 0 101 64"
            >
                <g
                    id="Gruppe_12"
                    data-name="Gruppe 12"
                    transform="translate(-515 -96)"
                >
                    <g
                        id="Rechteck_29"
                        data-name="Rechteck 29"
                        transform="translate(515 96)"
                        fill="none"
                        stroke="#71716f"
                        strokeWidth="2"
                    >
                        <rect width="101" height="64" rx="5" stroke="none" />
                        <rect
                            x="1"
                            y="1"
                            width="99"
                            height="62"
                            rx="4"
                            fill="none"
                        />
                    </g>
                    <path
                        id="Polygon_4"
                        data-name="Polygon 4"
                        d="M20.526,1.608a2,2,0,0,1,2.949,0l17.454,19.04A2,2,0,0,1,39.454,24H4.546a2,2,0,0,1-1.474-3.351Z"
                        transform="translate(524 128)"
                        fill="#707070"
                    />
                    <path
                        id="Polygon_5"
                        data-name="Polygon 5"
                        d="M28.114,3.129a3,3,0,0,1,4.771,0l24.44,32.052A3,3,0,0,1,54.94,40H6.06a3,3,0,0,1-2.386-4.819Z"
                        transform="translate(547 112)"
                        fill="#707070"
                    />
                    <circle
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="6"
                        cy="6"
                        r="6"
                        transform="translate(532 108)"
                        fill="#707070"
                    />
                </g>
            </svg>
        );

    return (
        <ImageUploadStyled>
            <div className="Zone">
                <div
                    className="Dropzone"
                    {...getRootProps()}
                    style={
                        uploadImage !== undefined
                            ? { backgroundImage: `url(${uploadImage})` }
                            : { backgroundImage: "none" }
                    }
                >
                    <input {...getInputProps()} />
                    {isDragActive ? <p>Bild hier ablegen</p> : previewImage}
                </div>
                <div className="Description">
                    <p>Bild in das Feld ziehen oder klicken.</p>
                    {imageError && (
                        <p className="Error">
                            Das Bild hat eine zu geringe Auflösung oder das
                            Format stimmt nicht.
                        </p>
                    )}
                </div>
            </div>
            <div className="Small">
                <p>
                    Nur <strong>JPG</strong> oder <strong>PNG</strong> Formate.
                    <br />
                    <strong>Empfehlung:</strong> Motiv im Querformat
                </p>
                <p>
                    Mindestens eine Auflösung von{" "}
                    <strong>3000px X 1925px</strong>
                    <br />
                    Die maximale Bildgröße ist von Ihrem Browser und Computer
                    abhängig. Sollte es zu Problemen kommen, kann dies an einer
                    zu großen Dateigröße liegen.
                </p>
            </div>
        </ImageUploadStyled>
    );
};

export default ImageUpload;
