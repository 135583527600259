import styled from "styled-components";

const AppStyled = styled.article`
    background-color: #f0f1f1;
    font-family: "Roboto Condensed";
    color: #707070;
    font-size: 18px;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;

    * {
        box-sizing: border-box;
    }
    h1,
    h2 {
        color: #1e3158;
    }
    h1 {
        font-family: "Oswald SemiBold";
        text-transform: uppercase;
        font-size: 25px;
        display: inline-block;
    }
    h2 {
        font-size: 20px;
    }

    header{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        padding: 1rem 0;
        margin: 0 1rem;
        border-bottom: 2px solid #1e3158;
        h1{
            margin: 0;
            order: 2;
        }

        .DGM-Logo{
            height: 6rem;
            order: 1;
        }
        @media (min-width: 650px){
            flex-wrap: nowrap;
            h1{
                order: 1;
            }
            .DGM-Logo{
                order: 2;
            }
        }
    }

    .flex {
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 4rem;

        @media (min-width: 650px){
            padding-bottom: 0;
        }
        .col-1-2 {
            width: 100%;
            padding: 1rem;
        }
    }

    .Small {
        padding-left: 1.75rem;
        font-size: 14px;
        position: relative;
        &:before {
            content: "i";
            font-family: "Roboto Condensed Italic";
            width: 1.25rem;
            height: 1.25rem;
            background-color: #707070;
            color: #f0f1f1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
        }
        p {
            margin: 0;
        }
        strong{
            font-family: "Roboto Condensed Bold";
        }
    }

    .InfoButton{
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        background-color: transparent;
        color: #707070;
        border: 1px solid #707070;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.4s ease;
        &:hover{
            background-color: #1e3158;
            color: #f0f1f1;
            border: 1px solid #1e3158;
        }
    }

    @media (min-width: 650px){
        .flex{
            flex-direction: row;
            .col-1-2{
                width: 50%
            }
        }
    }
`;

export default AppStyled;
