import styled from 'styled-components'

const ImageUploadStyled = styled.div`
    .Zone{
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }
    .Dropzone{
        background-color: #fff;
        border-radius: 5px;
        padding: 1rem;
        height: 100px;
        width:  180px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-size: cover;
        background-position: center;
        border: 1px solid #1e3158;
    }
    .Description{
        padding: 0 1rem;
        flex: 1;
        p{
            font-family: "Roboto Condensed Bold";
            color: #1e3158;
        }
        .Error{
            color: #ad0f2f;
        }
    }
`

export default ImageUploadStyled