import IntroStyled from './IntroStyled'
import Button from '../Button/Button'
import { textSettings } from '../../appSettings';

const Intro = ({ confirm }) => {
    return(
        <IntroStyled>
            <div className="ContentBox">
                <div className="Description" dangerouslySetInnerHTML={{ __html: textSettings.introText}}></div>
                <Button onClick={confirm}>Einverstanden</Button>
            </div>
        </IntroStyled>
    )
}

export default Intro