import React, { useState, useRef, useEffect   } from "react";
import GlobalFonts from "./fonts/fonts";

import Intro from "./components/Intro/Intro";
import Setup from "./components/setup/Setup";
import Preview from "./components/preview/Preview";
import Download from "./components/download/Download";
import CalculatingDialog from "./components/CalculatingDialog/CalculatingDialog";

import AppStyled from "./AppStyled";

import { logoSettings, sizeSettings, textSettings } from "./appSettings";

const App = () => {
    const refreshColSize = () => {
        if (colRef.current) {
            setColWidth(colRef.current.offsetWidth - 32);
        }
    };

    const convertBleedInPx = () => {
        return (settings.bleed / 25.4) * sizeSettings.ppi;
    };

    // Variables / Settings
    const a2Size = sizeSettings.a2Size;
    const a3Size = sizeSettings.a3Size;
    const a4Size = sizeSettings.a4Size;

    // State
    const [settings, setSettings] = useState({
        image: "",
        date: textSettings.date,
        info_1: "Musterveranstaltung, 10:00-18:00 Uhr",
        info_2: "Mustermühle, Musterstraße 99a",
        website: "www.windmuehle-musterhausen.de",
        qrcode: "www.windmuehle-musterhausen.de",
        logo: {
            value: 1,
            url: `${process.env.PUBLIC_URL}/logo/Muehlenverein_im_kreis_minden-luebbecke.svg`,
            label: "Mühlenverband im Kreis Minden-Lübecke e.V.",
        },
        bleed: sizeSettings.bleed,
        zoom: "100",
        panLR: "50",
        panTB: "50",
        photoCredit: ""
    });
    const [appSettings, setAppSettings] = useState(logoSettings);
    const [isInUse, setIsInUse] = useState(
        process.env.NODE_ENV === "development"
    );
    const [colWidth, setColWidth] = useState(360);
    const [bleedInPx, setBleedInPx] = useState(convertBleedInPx());
    const [isCalculating, setIsCalculating] = useState(false);

    // Functions
    const onConfirmHandler = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setIsInUse(true);
    };
    const onInfoHandler = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        setIsInUse(false);
    }

    const onCalculatingHandler = (status) => {
        console.log(status);
        setIsCalculating(status);
    };

    // Refs
    const prevRef = useRef();
    const colRef = useRef(null);

    // Effects
    useEffect(() => {
        refreshColSize();
    }, [isInUse]);

    useEffect(() => {
        const ls = localStorage;
        if (ls.getItem("dgm_date")) {
            if (ls.getItem("dgm_date").length > 0) {
                onLoadHandler();
            }
        }
    }, []);

    useEffect(() => {
        setBleedInPx(convertBleedInPx());
    }, [settings]);

    const onSaveClickHandler = () => {
        const ls = localStorage;
        if (ls) {
            // ls.setItem("dgm_image", settings.image);
            ls.setItem("dgm_date", settings.date);
            ls.setItem("dgm_info_1", settings.info_1);
            ls.setItem("dgm_info_2", settings.info_2);
            ls.setItem("dgm_website", settings.website);
            ls.setItem("dgm_qrcode", settings.qrcode);
            ls.setItem("dgm_logo_value", settings.logo.value);
            ls.setItem("dgm_bleed", settings.bleed);
            ls.setItem("dgm_zoom", settings.zoom);
            ls.setItem("dgm_panLR", settings.panLR);
            ls.setItem("dgm_panTB", settings.panTB);
            ls.setItem("dgm_photoCredit", settings.photoCredit);
        }

        onLoadHandler();
    };

    const onLoadHandler = () => {
        const ls = localStorage;
        if (ls) {
            const newSettings = {};
            // newSettings.image = ls.getItem("dgm_image");
            newSettings.date = ls.getItem("dgm_date");
            newSettings.info_1 = ls.getItem("dgm_info_1");
            newSettings.info_2 = ls.getItem("dgm_info_2");
            newSettings.website = ls.getItem("dgm_website");
            newSettings.qrcode = ls.getItem("dgm_qrcode");
            newSettings.bleed = ls.getItem("dgm_bleed");
            newSettings.zoom = ls.getItem("dgm_zoom");
            newSettings.panLR = ls.getItem("dgm_panLR");
            newSettings.panTB = ls.getItem("dgm_panTB");
            newSettings.photoCredit = ls.getItem("dgm_photoCredit");

            const logo = appSettings.logos.filter(
                (l) => l.value.toString() === ls.getItem("dgm_logo_value")
            );
            newSettings.logo = logo[0];
            newSettings.image = settings.image;

            setSettings(newSettings);
        }
    };

    return (
        <div>
            <GlobalFonts />
            <AppStyled className="Plakatgenerator">
                {isCalculating && <CalculatingDialog />}
                <header>
                    <h1>Plakatgenerator Deutscher Mühlentag</h1>
                    <img
                        className="DGM-Logo"
                        src={`${process.env.PUBLIC_URL}/static/dgm-muehlentag-logo.svg`}
                        alt="Deutsche Mühlentage"
                    />
                </header>
                <div className="flex">
                    {isInUse ? (
                        <>
                            <div className="col-1-2">
                                <Setup
                                    settings={settings}
                                    setSettings={setSettings}
                                    appSettings={appSettings}
                                    setAppSettings={setAppSettings}
                                    onSaveClick={onSaveClickHandler}
                                />
                            </div>
                            <div className="col-1-2" ref={colRef}>
                                <Preview
                                    settings={settings}
                                    ref={prevRef}
                                    colWidth={colWidth}
                                    a2Size={a2Size}
                                    bleedInPx={bleedInPx}
                                />
                                <Download
                                    settings={settings}
                                    preview={prevRef}
                                    a2Size={a2Size}
                                    a3Size={a3Size}
                                    a4Size={a4Size}
                                    bleedInPx={bleedInPx}
                                    onCalculating={onCalculatingHandler}
                                />
                            </div>
                            <div className="InfoButton" onClick={onInfoHandler}>
                                Informationen zum Plakatgenerator
                            </div>
                        </>
                    ) : (
                        <Intro confirm={onConfirmHandler} />
                    )}
                </div>
            </AppStyled>
        </div>
    );
};

export default App;
