import styled from 'styled-components'

const CalculatingDialogStyled = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: rgba(200, 200, 225, 0.25);
    backdrop-filter: blur(2px);

    .Window{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 2rem;
        border-radius: 2rem;
        color: #1e3158;
        text-align: center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);

        p{
            margin: 2rem 0 0;
        }
    }
`;

export default CalculatingDialogStyled;
