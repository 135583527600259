import ButtonStyled from './ButtonStyled'

const Button = ({ children, onClick, active=true }) => {
    return(
        <ButtonStyled disabled={!active} onClick={onClick}>
            {children}
        </ButtonStyled>
    )
}

export default Button