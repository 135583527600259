import Select from 'react-select'
import InputStyled from '../Input/InputStyled'

const SelectInput = ({ options, onChange, value}) => {
    const selectStyle = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #1e3158',
            borderColor: '#1e3158 !important',
            borderRadius: '5px',
            color: '#707070',
            padding: '0 0.75rem',
            fontWeight: 'bold',
            fontFamily: 'Roboto Condensed Bold',
            margin: '0.5rem 0',
            boxShadow: 'none',
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? '#ffffff' : '#1e3158',
          backgroundColor: state.isSelected ? '#1e3158' : '#ffffff',
          padding: "0.5rem 0.75rem",
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0'
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#1e3158',
            padding: '5px'
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
      
          return { ...provided, opacity, transition };
        }
    }

    return (
        <InputStyled>
            <label>
                <p>Landesverbandslogo</p>
                <Select 
                    options={options}
                    styles={selectStyle}
                    onChange={onChange}
                    value={value}
                />
            </label>
        </InputStyled>
    )
}

export default SelectInput