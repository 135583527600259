import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import CalculatingDialogStyled from './CalculatingDialogStyled';

const CalculatingDialog = () => {
    return (
        <CalculatingDialogStyled>
            <div className="Window">
                <Loader
                    type="Grid"
                    color="#1e3158"
                    height={80}
                    width={80}
                />
                <p>Plakat wird erzeugt.</p>
            </div>
        </CalculatingDialogStyled>
    );
};

export default CalculatingDialog;