import styled from 'styled-components'

const InputStyled = styled.div`
    margin: 1rem 0;
    &.withButton{
        margin-bottom: 0;
    }
    label{
        p{
            color: #1e3158;
            font-family: "Roboto Condensed Bold";
            margin: 0;
        }
        input, textarea{
            background-color: #ffffff;
            border: 1px solid #1e3158;
            padding: 0.5rem 0.75rem;
            font-size:18px;
            width: 100%;
            border-radius: 5px;
            color: #1e3158;
            font-family: "Roboto Condensed Bold";
            margin: 0.125rem 0;
        }

        input[type=range]{
            /* -webkit-appearance: none; */
            appearance: none;
            width: 100%;
            height: 1rem;
            padding: 0;
            background: #fff;
            outline: none; 
            opacity: 0.7;
            transition: opacity .2s;

            &:hover{
                opacity: 1;
            }

            &::-webkit-slider-thumb{
                /* -webkit-appearance: none; */
                appearance: none;
                width: 1rem;
                height: 1rem;
                background: #1e3158;
                cursor: pointer;
                border-radius: 5px;
            }
            &::-moz-range-thumb{
                width: 1rem;
                height: 1rem;
                background: #1e3158;
                cursor: pointer;
                border-radius: 5px;
            }
        }
    }
`

export default InputStyled