import styled from 'styled-components';

const SetupStyled = styled.section`
    .Image_settings{
        border: 1px solid #1e3158;
        border-radius: 5px;
        padding: 1rem;
        margin-top: 1rem;

        p{
            color: #1e3158;
            margin: 0 0 1rem;
        }
        label p{
            margin: 0;
        }
    }
    .Info{
        margin-bottom: 1rem;
    }
    .SaveOption{
        hr{
            border: none;
            height: 1px;
            display: solid;
            width: 100%;
            margin: 3rem 0 2rem 0;
            background-color: #1e3158;
        }
        button{
            margin-bottom: 1rem;
        }
    }
`;

export default SetupStyled;