// import {ReactComponent as MuehleLogoMindenLuebbeck} from '../../images/MuehlenLogo-minden-luebeke_01.svg'
// import {ReactComponent as MuehleLogoMindenLuebbeck2} from '../../images/MuehlenLogo-minden-luebeke_02.svg'

const VariableLogo = ({ logo }) => {
    if(!logo.url) return null
    return (
        <div className="Logo Logo-Variable">
            <img src={logo.url} alt={logo.label} />
        </div>
    )
}

export default VariableLogo