import Button from "../Button/Button";
import Input from "../Input/Input";
import ImageUpload from "../ImageUpload/ImageUpload";
import SelectInput from "../SelectInput/SelectInput";

import SetupStyled from './SetupStyled';

const Setup = ({ settings, setSettings, appSettings, onSaveClick }) => {
    const onChangeHanlder = (event) => {
        const newSettings = {
            ...settings,
            [event.target.attributes.dataid.value]: event.target.value,
        };
        setSettings(newSettings);
    };
    const onSelectChangeHandler = (e) => {
        const newSettings = {
            ...settings,
            logo: e,
        };
        setSettings(newSettings);
    };

    return (
        <SetupStyled>
            <h2>Einstellungen</h2>
            <ImageUpload setSettings={setSettings} settings={settings} />
            {
                settings.image && (
                    <div className="Image_settings">
                        <p>Für die Wahl des Bildausschnittes, Bild hier ggf. zoomen und verschieben.</p>
                        <Input 
                            title="Zoom"
                            type="range"
                            minVal="100"
                            maxVal="175"
                            value={settings.zoom ? settings.zoom : "0"}
                            dataId="zoom"
                            valueChange={onChangeHanlder}
                        />
                        <Input 
                            title="Links / Rechts"
                            type="range"
                            minVal="0"
                            maxVal="100"
                            value={settings.panLR ? settings.panLR : "0"}
                            dataId="panLR"
                            valueChange={onChangeHanlder}
                        />
                        <Input 
                            title="Oben / Unten"
                            type="range"
                            minVal="0"
                            maxVal="100"
                            value={settings.panTB ? settings.panTB : "0"}
                            dataId="panTB"
                            valueChange={onChangeHanlder}
                        />
                        <Input 
                            title="Foto Copyright (falls vorhanden)"
                            type="text"
                            placeholder=""
                            value={settings.photoCredit ? settings.photoCredit : ""}
                            dataId="photoCredit"
                            valueChange={onChangeHanlder}
                            maxLength={40}
                        />
                    </div>
                )
            }
            
            {/* <Input
                title="Datum"
                type="text"
                placeholder=""
                value={settings.date ? settings.date : ""}
                dataId="date"
                valueChange={onChangeHanlder}
                maxLength={28}
            /> */}
            <Input
                title="Infotext (Zeile 1)"
                type="text"
                placeholder=""
                value={settings.info_1 ? settings.info_1 : ""}
                dataId="info_1"
                valueChange={onChangeHanlder}
            />
            <Input
                title="Infotext (Zeile 2)"
                type="text"
                placeholder=""
                value={settings.info_2 ? settings.info_2 : ""}
                dataId="info_2"
                valueChange={onChangeHanlder}
            />
            <Input
                title="Eigene Website (Mühle oder Verein)"
                type="url"
                placeholder=""
                value={settings.website ? settings.website : ""}
                dataId="website"
                valueChange={onChangeHanlder}
            />
            <Input
                title="QR-Code eigene Website"
                type="url"
                dataId="qrcode"
                valueChange={onChangeHanlder}
                maxLength={999}
                value={settings.qrcode ? settings.qrcode : ""}
            />
            <SelectInput
                options={appSettings.logos}
                onChange={onSelectChangeHandler}
                value={settings.logo}
            />
            <div className="Info">
                <Input
                    title="Beschnittzugabe (mm)"
                    type="number"
                    dataId="bleed"
                    valueChange={onChangeHanlder}
                    value={settings.bleed ? settings.bleed : ""}
                    minVal={0}
                    maxVal={5}
                    step={1}

                />
                <div className="Small">
                    <p><strong>Nur für die Bestellung bei einer Druckerei notwendig!</strong> Benötigte Beschnittzugabe bitte vorab bei der Druckerei erfragen.</p>
                </div>
            </div>
            <div className="SaveOption">
                <hr />
                <Button
                    onClick={onSaveClick}
                >Einstellungen speichern</Button>
                <div className="Small">
                    <p>Die Einstellungen werden lokal im Browser gespeichert.</p>
                    <p>
                        Sollte dieser Speicher geleert werden, werden auch diese
                        Einstellungen gelöscht. <strong>Das Foto wird nicht gespeichert.</strong>
                    </p>
                </div>
            </div>
        </SetupStyled>
    );
};

export default Setup;
