import styled from 'styled-components'

const IntroStyled = styled.div`
    min-height: 800px;
    width: 100%;
    text-align: left;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    
    .ContentBox{
        padding: 1rem;
        text-align: center;

        .Description{
            text-align: left;
            line-height: 1.5;
            font-size: 16px;
            max-width: 45rem;
            margin-left: 0;
            h2, h3{
                color: #1e3158;
                font-family: "Oswald SemiBold";
            }
            h2{
                font-weight: bold;
                margin-bottom: 0;
            }
            h3{
                font-weight: bold;
                text-transform: uppercase;
                margin: 2rem 0 0 0;
                font-size: 18px;
            }
            h4{
                color: #1e3158;
                margin: 0;
                font-weight: normal;
                & + p {
                    margin-top: 0;
                }
            }
            a{
                &:link, &:visited{
                    color: #ad0f2f;
                }
            }
            .square{
                display: inline-block;
                width: 0.75rem;
                height: 0.75rem;
                background-color: #ad0f2f;
                margin-right: 0.5rem;
            }
        }

        button{
            font-size: 1.5rem;
            display: block;
            margin: 2rem auto;
        }
    }
`

export default IntroStyled