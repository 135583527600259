import { forwardRef } from "react";
import PreviewStyled from "./PreviewStyled";
import QRCode from "qrcode.react";
import VariableLogo from "./VariableLogo";

const Preview = forwardRef((props, ref) => {
    const previewSize = {
        width: props.colWidth,
        height: props.colWidth * (props.a2Size.height / props.a2Size.width),
        scaleFactor: props.colWidth / props.a2Size.width,
    };

    return (
        <PreviewStyled bleed={props.bleedInPx} scale={previewSize.scaleFactor}>
            <h2>Vorschau</h2>
            {console.log(window.devicePixelRatio)}
            <div
                className="PrevWrapper"
                style={{
                    width: previewSize.width,
                    height: previewSize.height,
                }}
            >
                <div className="Overlay"></div>
                <div
                    id="Preview"
                    ref={ref}
                    style={{
                        backgroundColor: "#1e3158",
                        padding: 0,
                        width: props.a2Size.width + props.bleedInPx * 2 + "px",
                        height:
                            props.a2Size.height + props.bleedInPx * 2 + "px",
                        transform: `scale(${previewSize.scaleFactor})`,
                        transformOrigin: "top left",
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div
                        className="header_image_wrapper"
                        style={
                            props.settings.image
                                ? {
                                      backgroundImage: `url(${props.settings.image})`,
                                      backgroundSize: `${props.settings.zoom}%`,
                                      backgroundPositionX: `${props.settings.panLR}%`,
                                      backgroundPositionY: `${props.settings.panTB}%`,
                                  }
                                : { backgroundColor: "#fff" }
                        }
                    >
                        {props.settings.photoCredit ? (
                            <p className="photoCredit">{`Foto: ${props.settings.photoCredit}`}</p>
                        ) : (
                            " "
                        )}
                    </div>
                    <img
                        className="Call"
                        src={`${process.env.PUBLIC_URL}/static/Call_01@3x.png`}
                        type="image/svg+xml"
                        alt="Wir laden ein"
                    />
                    <p className="Title">Deutscher Mühlentag</p>
                    <p className="Date">
                        {props.settings.date
                            ? props.settings.date
                            : "Platzhalter, 00. Mai 2021"}
                    </p>
                    <p className="Info Info_1">
                        {props.settings.info_1
                            ? props.settings.info_1
                            : "Uhrzeit"}
                    </p>
                    <p className="Info Info_2">
                        {props.settings.info_2
                            ? props.settings.info_2
                            : "Anschrift"}
                    </p>
                    <p className="Info Website">
                        {props.settings.website
                            ? props.settings.website
                            : "Website"}
                    </p>
                    {props.settings.qrcode ? (
                        <div className="Qrcode">
                            <QRCode
                                value={
                                    props.settings.qrcode
                                        ? props.settings.qrcode
                                        : ""
                                }
                                bgColor="transparent"
                                fgColor="#ffffff"
                                renderAs="svg"
                                level="H"
                                size={468}
                            />
                            <p>Scannen und Infos erhalten!</p>
                        </div>
                    ) : (
                        ""
                    )}
                    <p className="Info DMWebsite">www.deutsche-muehlen.de</p>
                    <VariableLogo logo={props.settings.logo} />
                    <div className="Logo Logo-Muehle">
                        {/* <MuehleLogo /> */}
                        <img
                            type="image/png"
                            src={`${process.env.PUBLIC_URL}/static/Logo_Muehle_01@3x.png`}
                            alt="Logo deutsche Mühlen"
                        />
                    </div>
                    <div className="Sponsoren">
                        <img
                            type="image/png"
                            src={`${process.env.PUBLIC_URL}/sponsoren/Footer-V4@3x.png`}
                            alt="Sponsoren Logowand"
                        />
                    </div>
                </div>
            </div>
            <div className="Small">
                <p>
                    Bei dieser Ansicht handelt es sich um eine Vorschau-Funktion
                    mit geringer Auflösung.
                </p>
                <p>
                    Prüfen Sie bitte vor dem Ausdruck die heruntergeladenen
                    PDF-Dateien.
                </p>
            </div>
        </PreviewStyled>
    );
});

export default Preview;
