import { useState, useEffect } from 'react';
import Button from "../Button/Button";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import DownloadSyted from './DownloadStyled';
import { call, buildInputFile } from 'wasm-imagemagick';


const Download = ({ preview, a2Size, a3Size, a4Size, settings, bleedInPx, onCalculating }) => {
    const onDinA4ClickHandler = (sizeDIN) => {
        onCalculating(true);

        let settings = {
            width: a2Size.width + (bleedInPx * 2),
            height: a2Size.height + (bleedInPx * 2),
            scaleFactor: 1,
        };
        if (sizeDIN === "a3") {
            settings = {
                width: a3Size.width + (bleedInPx * 2),
                height: a3Size.height + (bleedInPx * 2),
                scaleFactor: (a3Size.width + bleedInPx * 2) / (a2Size.width + bleedInPx * 2),
            }
        } else if (sizeDIN === "a4") {
            settings = {
                width: a4Size.width + (bleedInPx * 2),
                height: a4Size.height + (bleedInPx * 2),
                scaleFactor: (a4Size.width + bleedInPx * 2) / (a2Size.width + bleedInPx * 2),
            }
        }

        html2canvas(preview.current, {
            width: settings.width,
            height: settings.height,
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            x: preview.current.getBoundingClientRect().x,
            y: preview.current.getBoundingClientRect().y,
            scale: 1,
            dpi: 300,
            backgroundColor: '#1e3158',
            onclone: (cDoc) => {
                const cPreview = cDoc.getElementById("Preview");
                cPreview.style.transform = `scale(${settings.scaleFactor})`;
            },
            // useCORS: true,
        }).then(async (canvas) => {
            let blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
            
            const content = new Uint8Array(await blob.arrayBuffer());
            const image = { name: 'srcFile.jpeg', content };
            const cmykProfile = await buildInputFile(`${process.env.PUBLIC_URL}/static/ISOcoated_v2_300_eci.icc`);
            const rgbProfile = await buildInputFile(`${process.env.PUBLIC_URL}/static/sRGB2014.icc`);
            // convert rgb01.jpeg +profile -set colorspace sRGB -colorspace CMYK -profile Profiles/USWebCoatedSWOP.icc -negate cmyk01.jpg
            // +profile -profile Profiles/sRGB.icc -profile Profiles/USWebCoatedSWOP.icc
            const command = ["convert", "srcFile.jpeg", '-profile', 'sRGB2014.icc', '-profile', 'ISOcoated_v2_300_eci.icc', '-density', '300', 'out.jpeg'];
            const result = await call([image, cmykProfile, rgbProfile], command);
            // const imageData = await call([{name: 'test.jpg', content: result.outputFiles[0]}], ['identify', '-verbose', 'test.jpeg']);


            if(result.exitCode !== 0){
                return alert('There was an error: ' + result.stderr.join('\n'))
            }

            const outImg = result.outputFiles[0];
            
            
            const outblob = new Blob([outImg['blob']], { type: "image/jpeg" }); // application/pdf

            const reader = new FileReader();
            reader.readAsDataURL(outblob);
            reader.onloadend = function() {
                const imageData = reader.result;
                
                // const link = document.createElement("a");
                // link.download = `DGM_Plakat_Din${sizeDIN.toUpperCase()}.jpeg`;
                // link.href = imageData;
                // link.click();

                const docWidth = settings.width / 300 * 25.4;
                const docHeight = settings.height / 300 * 25.4;
                const pdf = new jsPDF({
                    orientation: 'Portrait',
                    unit: 'mm',
                    format: [docWidth, docHeight],
                    putOnlyUsedFonts:true
                });
                pdf.setProperties({
                    title: 'Muehlentage',
                    subject: 'DEUTSCHER MÜHLENTAG',		
                    author: 'DGM - Plakatgenerator'
                });
                pdf.setCreationDate(new Date());
                pdf.addImage({
                    imageData: imageData,
                    format: 'JPG',
                    x: 0,
                    y: 0,
                    width: docWidth,
                    height: docHeight
                });
                pdf.getCurrentPageInfo().pageContext.trimBox = {
                    bottomLeftX: 0,
                    bottomLeftY: 0,
                    topRightX: docWidth * pdf.internal.scaleFactor,
                    topRightY: docHeight * pdf.internal.scaleFactor
                };
                console.log(pdf.getCurrentPageInfo());
                pdf.save(`DGM_Plakat_Din${sizeDIN.toUpperCase()}.pdf`);
            }
            
        }).then(() => {
            onCalculating(false);
        });
    };

    // State
    const [buttonActive, setButtonActive] = useState(false);

    // Effects
    useEffect(() => {
        setButtonActive(settings.image !== undefined && settings.image !== '');
    }, [settings.image]);

    return (
        <DownloadSyted>
            <h2>PDF-Downloads:</h2>
                <Button active={buttonActive} onClick={() => onDinA4ClickHandler("a4")}>DIN A4</Button>
                <Button active={buttonActive} onClick={() => onDinA4ClickHandler("a3")}>DIN A3</Button>
                <Button active={buttonActive} onClick={() => onDinA4ClickHandler("a2")}>DIN A2</Button>
        </DownloadSyted>
    );
};

export default Download;
