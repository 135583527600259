import styled from 'styled-components'

const ButtonStyled = styled.button`
    border: none;
    background-color: #1e3158;
    color: #ffffff;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    transition: background-color .4s ease;
    &:not(:disabled){
        &:hover{
            background-color: #516691;
        }
    }


    &:disabled{
        opacity: 0.3;
        cursor: not-allowed;
    }
`

export default ButtonStyled