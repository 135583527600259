export const logoSettings = {
    logos: [
        {
            value: 1,
            url: `${process.env.PUBLIC_URL}/logo/Muehlenverein_im_kreis_minden-luebbecke@3x.png`,
            label: "Mühlenverband im Kreis Minden-Lübecke e.V.",
        },
        {
            value: 2,
            url: `${process.env.PUBLIC_URL}/logo/westfaelisch-lippische_muehlenvereinigung@3x.png`,
            label: "Westfälisch-Lippische Mühlenvereinigung e. V.",
        },
        {
            value: 3,
            url: `${process.env.PUBLIC_URL}/logo/muehlenvereinigung_berlin-brandenburg@3x.png`,
            label: "Mühlenvereinigung Berlin-Brandenburg e. V.",
        },
        {
            value: 4,
            url: `${process.env.PUBLIC_URL}/logo/dgm_landesverband_baden-wuerttemberg@3x.png`,
            label: "DGM Landesverband Baden-Württemberg",
        },
        {
            value: 5,
            url: `${process.env.PUBLIC_URL}/logo/hessicher_landesverband_zur_erhaltung_und_nurzung_von_muehlen_hlm@3x.png`,
            label: "Hessischer Landesverband zur Erhaltung und Nutzung von Mühlen (HLM) e. V.",
        },
        {
            value: 7,
            url: `${process.env.PUBLIC_URL}/logo/Muehlenverein_mecklenburg-vorpommern@3x.png`,
            label: "Mühlenverein Mecklenburg-Vorpommern e. V.",
        },
        {
            value: 8,
            url: `${process.env.PUBLIC_URL}/logo/rheinischer_muehlenverband@3x.png`,
            label: "Rheinischer Mühlenverband e. V.",
        },
        {
            value: 9,
            url: `${process.env.PUBLIC_URL}/logo/arbeitskreis_muehlen_sachsen-anhalt@3x.png`,
            label: "Arbeitskreis Mühlen Sachsen-Anhalt e. V.",
        },
        {
            value: 10,
            url: `${process.env.PUBLIC_URL}/logo/saechsischer_muehlenverein@3x.png`,
            label: "Sächsischer Mühlenverein e. V.",
        },
        {
            value: 11,
            url: `${process.env.PUBLIC_URL}/logo/thueringer_landesverein_fuer_muehlenerhaltung_muehlenkunde_tvm@3x.png`,
            label: "Thüringer Landesverein für Mühlenerhaltung & Mühlenkunde (TVM) e. V.",
        },
        {
            value: 12,
            url: `${process.env.PUBLIC_URL}/logo/bayrischerLandesverband@3x.png`,
            label: "Bayrischer Landesverband für Mühlenkunde & Mühlenerhaltung e. V.",
        },
        {
            value: 13,
            url: `${process.env.PUBLIC_URL}/logo/niedersachsen-bremen@3x.png`,
            label: "Mühlenvereinigung Niedersachsen-Bremen e. V.",
        },
        {
            value: 14,
            // url: `${process.env.PUBLIC_URL}/logo/niedersachsen-bremen@3x.png`,
            label: "Direktmitglied",
        },
    ],
};

export const sizeSettings = {
    a2Size: {
        width: 4961,
        height: 7016,
    },
    a3Size: {
        width: 3508,
        height: 4961,
    },
    a4Size: {
        width: 2480,
        height: 3508,
    },
    ppi: 300,
    bleed: 3,
};

export const textSettings = {
    date: "Pfingstmontag, 20. Mai 2024",
    introText: `
    <h2>DER PLAKATGENERATOR ZUM DEUTSCHEN MÜHLENTAG<br/>
    IHR VERANSTALTUNGS-PLAKAT EINFACH UND SCHNELL SELBST GESTALTEN!</h2>

    <p>Die historischen Mühlen öffnen am Pfingstmontag, 20.05.2024 anlässlich des Deutschen Mühlentages ihre Türen. Die Deutsche Gesellschaft für Mühlenkunde und Mühlenerhaltung (DGM) e.V. und ihre Landes- und Regionalverbände laden zu diesem besonderen Aktionstag ein.</p>

    <h4>Der Deutsche Mühlentag erstrahlt in neuem Licht.</h4>
    <p>Mit der Eintragung als Marke beim Deutschen Patent- und Markenamt verdeutlicht die DGM einmal mehr die große Bedeutung des Deutschen Mühlentages für die hiesige Mühlenlandschaft.</p>

    <p>Im Zuge der Einführung eines einheitlichen durchgängigen Corporate Designs in der DGM wurde auch das Mühlentagsplakat neugestaltet.</p>

    <p>Die Deutsche Gesellschaft für Mühlenkunde und Mühlenerhaltung (DGM) e.V. dankt allen Teilnehmern, die diesen Tag mit ihrem Beitrag so bedeutend machen und bietet ihnen mit dem neuen Plakatgenerator ein besonderes Angebot zur Unterstützung bei der Vorbereitung und Durchführung ihrer Veranstaltung.</p>

    <p>Mit Hilfe des Plakatgenerators ist es allen Teilnehmern möglich ganz unkompliziert in nur wenigen Minuten ein eigenes Plakat für ihre Veranstaltung zu gestalten.<br />
    Die Teilnehmer haben hier die Möglichkeit ein eigenes Foto hochzuladen und Informationen zu ihrer Veranstaltung zu hinterlegen. Über einen eigens generierten QR-Code kann jeder zudem seine Veranstaltungs-Details online bereitstellen und teilen.<br />
    Die fertigen Plakate stehen als PDF-Datei in drei Größen (DIN A4, A3 und A2) zum sofortigen Download bereit. Um eine Veranstaltung zu bewerben, können diese ausgedruckt, aber auch digital versendet werden. Die Dateien sind so generiert, dass sie auch direkt in einer Druckerei zum Druck genutzt werden können. In dem Fall ist es erforderlich für die Dateienerstellung zuvor bei der Druckerei Ihrer Wahl die Beschnittzugabe zu erfragen und im Generator einzugeben. Bei den nachstehenden empfohlenen Druckereien ist die Beschnittzugabe bereits aufgeführt, weitverbreitet ist eine Zugabe von 3 mm.</p>

    <p>Der Plakatgenerator ersetzt die bisher in gedruckter Form zur Verfügung gestellten Eröffnungsplakate sowie die digitale PDF-Vorlage zur Eigenbeschriftung. <br />
    Wenn kein eigenes Plakat erstellt werden soll, besteht weiterhin die Möglichkeit gedruckte Eröffnungsplakate (ohne Leerbereiche) über die Mühlentagbeauftragten der Mitgliedsverbände von der DGM zu beziehen.</p>

    <h3>Empfehlungen für den Druck bei einer Druckerei:</h3>
    <p><span class="square"></span><strong>mittwalddruck & medien Ulrich Meyer GmbH & Co. KG</strong><br />
    Neuer Weg 5, 32339 Espelkamp<br />
    Beschnittzugabe: 3mm<br />
    Telefon: 05772 916688-0 <br />
    <a href="mailto:info@mittwaldmedien.de">info@mittwaldmedien.de</a></p>

    <p><span class="square"></span></span><strong>Flyeralarm.de:</strong><br />
    Beschnittzugabe: 3mm<br />
    <a target="_blank" href="https://www.flyeralarm.com/de/p/plakate-im-digital-und-offsetdruck-ab-1-stueck-10524579.html">www.flyeralarm.de</a></p>

    <p><span class="square"></span><strong>Wir-machen-Druck.de:</strong><br>
    Beschnittzugabe: 3mm<br/>
    <a target="_blank" href="https://www.wir-machen-druck.de/plakat-din-a2-hoch-420-x-594-mm-einseitig-40farbig-bedruckt-topseller.html">Format DIN A2</a><br/>
    <a target="_blank" href="https://www.wir-machen-druck.de/plakat-din-a3-hoch-297-x-420-mm-einseitig-40farbig-bedruckt-topseller.html">Format DIN A3</a><br/>
    <a target="_blank" href="https://www.wir-machen-druck.de/plakat-din-a4-hoch-210-x-297-mm-einseitig-40farbig-bedruckt-topseller.html">Format DIN A4</a></p>

    <h3>Verwendungshinweise:</h3>
    <p>Der Deutsche Mühlentag ist eine geschützte Marke der Deutschen Gesellschaft für Mühlenkunde und Mühlenerhaltung (DGM) e.V.<br/>
    Die Nutzung des Plakatgenerators ist kostenfrei und steht ausschließlich den angemeldeten Teilnehmern des Deutschen Mühlentages zur Bewerbung Ihrer Mühlentagveranstaltung zur Verfügung.<br />
    Die missbräuchliche oder ungenehmigte Verwendung wird als Verstoß im Sinne des Markenrechtes rechtlich verfolgt und geahndet.</p>


    <h3>Anmeldung zur Teilnahme am Deutschen Mühlentag:</h3>
    <p>Mitgliedsmühlen, die teilnehmen möchten, melden sich bei Ihrem zuständigen Landes- oder Regionalverband gem. dortiger Terminvorgaben an. Alle teilnehmenden Mühlen werden in der Milldatabase, der Mühlendatenbank der DGM, erfasst. Für die DGM-seitige Kommunikation mittels Pressemitteilungen und Verteilfassungen von Teilnehmerverzeichnissen muss die Eintragung in Milldatabase bis zum 2. April 2024 erfolgt sein. Nachmeldungen können ggf. nicht mehr in den Veröffentlichungen berücksichtigt werden. Die jeweilige Kommunikation durch den zuständigen Landes- oder Regionalverband ist von diesem Stichtag unbenommen.</p>


    <h3>Informationen zum Deutschen Mühlentag:</h3>
    <p>Deutsche Gesellschaft für Mühlenkunde und Mühlenerhaltung (DGM) e.V.,<br/>Schwarzer Weg 2 (Mühlen-Bauhof), 32469 Petershagen-Frille,<br/>Telefon: +49 5702-2694 oder -4863, Fax: +49 5702-4963, E-Mail: <a href="mailto:geschaeftsstelle@deutsche-muehlen.de">geschaeftsstelle@deutsche-muehlen.de</a> und unter: <a href="www.deutsche-muehlen.de/deutscher-muehlentag/" target="_blank" rel="noopener noreferrer">www.deutsche-muehlen.de/deutscher-muehlentag/</a></p>

    <h3>Technische Hinweise zur Benutzung des Plakatgenerators:</h3>
    <p><strong>Browser Support:</strong> Alle gängigen modernen Browser wie Google Chrome, Microsoft Edge, Mozilla Firefox und Safari. Mit dem Internet Explorer 11 kann es zu Einschränkungen kommen. Ältere Internet Explorer werden nicht unterstützt.<br/>
    Bitte beachten Sie, dass Einstellungen in Ihrem Browser bezüglich der Darstellung von Schriften, Einfluss auf die Plakatgenerierung haben können. Insbesondere die Funktion „nur Text zoomen“ führt zu einem verzerrten Textbild des Plakates in der Ansicht und in der Dateigenerierung. Der Plakatgenerator ist für die am weitesten verbreiteten Browser und Einstellungen optimiert.<br/>
    Sollten Sie sich hierbei nicht sicher sein, freuen wir uns Ihnen aktiv weiterhelfen zu können.</p>
    <p>Ihr Ansprechpartner: Vizepräsident Carsten Schmidt, Mobil +49 170 34 17 734, E-Mail: <a href="mailto:c.schmidt@deutsche-muehlen.de">c.schmidt@deutsche-muehlen.de</a></p>
    `,
};
