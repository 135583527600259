import styled from 'styled-components'

const PreviewStyled = styled.section`
    .PrevWrapper{
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        margin-bottom: 1rem;
        box-sizing: content-box;
        overflow: hidden;
        position: relative;

        .Overlay{
            position: absolute;
            /* top: ${({bleed}) => 0  + 'px'};
            left: ${({bleed}) => 0 + 'px'};
            width: ${({scale}) => 4961 * scale + 'px'};
            height: ${({scale}) => 7016 * scale + 'px'};
            background: ${ () => {return `url(${process.env.PUBLIC_URL}/static/vorlage.png)`} };
            background-position: center;
            background-size: cover; */
            background-color: transparent;
            opacity: 0.4;
            z-index: 99;
        }

        #Preview{
            /* aspect-ratio: 1 / 1.414; */
            position: absolute;
            user-select: text;
            left: ${({bleed, scale}) => {
                return (bleed * scale) * -1 + 'px';
            }};
            top: ${({bleed, scale}) => (bleed * scale) * -1 + 'px'};


            .header_image_wrapper{
                /* aspect-ratio: 297 / 190.5; */
                height: ${({bleed}) => 3183 + bleed + 'px'};
                width: calc(100 % + 18px);
                background-color: #fff;
                /* background-position: center; */
                position: absolute;
                
                top: 0;
                left: -18px;
                right: 0;
                z-index: 0;
                img{
                    width: 100%;
                    height: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }
                .photoCredit{
                    position: absolute;
                    z-index: 999;
                    right: ${({bleed}) => 336 + bleed + 'px'};
                    text-align: right;
                    top: ${({bleed}) => 2923 + bleed + 'px'};
                    color: #fff;
                    font-size: 90px;
                    text-shadow: 0 0 100px rgba(0,0,0,0.2);
                    font-style: italic;
                }
            }

            .Call{
                width: 2019px;
                position: absolute;
                top: ${({bleed}) => 2994 + bleed + 'px'};
                left: ${({bleed}) => -51 + bleed + 'px'};
                z-index: 1;
            }

            p{
                color: #fff;
            }

            .Title,
            .Date,
            .Info,
            .Qrcode,
            .DMWebsite{
                /* left: 412px; */
                left: ${({bleed}) => 412 + bleed + 'px'};
            }
            .Title,
            .Date{
                text-transform: uppercase;
                font-family: 'Oswald SemiBold';
                font-weight: bold;
            }
            .Title,
            .Date,
            .Info,
            .Qrcode,
            .DMWebsite,
            .Logo{
                position: absolute;
                z-index: 1;
                margin: 0;
            }

            .Title{
                /* font-size: 324px; */
                font-size: ${() => 324 * 1 + 'px'};
                position: absolute;
                /* top: 3466px; */
                top: ${({bleed}) => 3466 + bleed + 'px'};
                z-index: 1;
            }

            .Date{
                font-size: 265px;
                /* top: 3902px; */
                top: ${({bleed}) => 3902 + bleed + 'px'};
            }

            .Info{
                font-family: 'Roboto Condensed Bold';
                font-size: 162px;

                &_1{
                    /* top: 4332px; */
                    top: ${({bleed}) => 4332 + bleed + 'px'};
                }
                &_2{
                    /* top: 4556px; */
                    top: ${({bleed}) => 4556 + bleed + 'px'};
                }

                &.Website{
                    /* top: 4790px; */
                    top: ${({bleed}) => 4790 + bleed + 'px'};
                }
            }

            .Qrcode{
                /* top: 5371px; */
                top: ${({bleed}) => 5371 + bleed + 'px'};
                p{
                    position: absolute;
                    bottom: -7px;
                    left: 560px;
                    font-size: 106px;
                    line-height: 1.1;
                    font-family: 'Roboto Condensed Italic';
                    font-style: italic;
                    margin: 0;
                    width: 580px;
                }
            }
            .DMWebsite{
                /* bottom: 947px; */
                bottom: ${({bleed}) => 947 + bleed + 'px'};
                font-size: 130px;
                font-family: 'Roboto Condensed Bold';
            }

            .Logo{
                /* bottom: 974px; */
                bottom: ${({bleed}) => 974 + bleed + 'px'};
                
                &-Variable{
                    height: 660px;
                    width: 900px;
                    /* right: 1667px; */
                    right: ${({bleed}) => 1667 + bleed + 'px'};
                    img{
                        width: auto;
                        height: 100%;
                        display: block;
                        float: right;
                    }
                }

                &-Muehle{
                    width: 1133px;
                    height: 943px;
                    /* right: 336px; */
                    right: ${({bleed}) => 336 + bleed + 'px'};
                    img{
                        width: 100%;
                        height: auto;
                        display: block;
                    }
                }
            }

            .Sponsoren{
                background-color: #fff;
                text-align: center;
                position: absolute;
                bottom: 0;
                left: -18px;
                padding: ${({bleed}) => `0px ${bleed}px ${bleed}px ${bleed}px`};
                width: ${({bleed}) => 4961 + (bleed * 2) + 18 + 'px'};
                height: ${({bleed}) => 778 + bleed + 'px'};

                img{
                    height: 100%;
                    width: auto;
                    display: block;
                }
            }
        }
    }
`

export default PreviewStyled