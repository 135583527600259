import { createGlobalStyle } from "styled-components";

// import OswaldBold from './fonts/Oswald-Bold.ttf';
import OswaldSemiBold from "./Oswald-SemiBold.ttf";
import RobotoCondensedBold from "./RobotoCondensed-Bold.ttf";
import RobotoCondensedItalic from "./RobotoCondensed-Italic.ttf";
import RobotoCondensedRegular from './RobotoCondensed-Regular.ttf';

const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: "Oswald SemiBold";
        src: url(${OswaldSemiBold}) format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Condensed";
        src: url(${RobotoCondensedRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Condensed Bold";
        src: url(${RobotoCondensedBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Condensed Italic";
        src: url(${RobotoCondensedItalic}) format('truetype');
        font-weight: 300;
        font-style: italic;
    }
`;

export default GlobalFonts;
